import React from "react";
import { graphql } from "gatsby";

import { Grid } from '@mui/material';

import ProductCard from 'components/ProductCard'

import Breadcrumbs from "components/Breadcrumbs";
import Seo from "components/Seo";

import { Product } from "types";

type ProductsQueryProps = {
  allProduct: {
    edges: {
      id: string,
      node: Product,
    }[]
  }
}

const Products: React.FC<{ data: ProductsQueryProps}> = ({ data}) => {
  const { allProduct } = data;
  return (
    <>
      <Seo title={`Produse`}/>
      <Breadcrumbs
        dataBreadcrumb={
          [
            {
              id: 'produse',
              name: 'Produse',
            },
          ]}
      />
      <Grid container spacing={3}>
        {
          allProduct.edges.map(({ node }) => {
            return (
              <Grid item  key={node.id} xs={12} sm={4} md={3}>
                <ProductCard product={node} />
              </Grid>
            )
          })}
      </Grid>
    </>
  )
}

export default Products;

export const query = graphql`
query {
  allProduct {
    edges {
      node {
        code
        id
        asset_1
        category
        name
        new
        stock
      }
    }
  }
}
`

